<template>
    <div class="video-upload">
        <div class="page-title">上传视频</div>
        <div class="container">
            <div class="head">
                <div class="label">视频信息</div>
                <div class="line"></div>
            </div>
            <div class="content-box">
                <div class="row">
                    <div class="label">
                        <span class="red">*</span>
                        <span>视频名称</span>
                    </div>
                    <div class="input-box">
                        <input v-model="params.name" type="text" placeholder="请输入不超过20字的标题" maxlength="20">
                        <div class="num">{{ params.name.length }}/20</div>
                    </div>
                </div>
                <div class="row">
                    <div class="label">
                        <span class="red">*</span>
                        <span>视频信息</span>
                    </div>
                    <div class="video-box">
                        <div class="upload-box">
                            <div class="video">
                                <div class="video-thumb">
                                    <img :src="detail.thumb">
                                </div>
                                <div class="video-info">
                                    <div class="info-label">视频时长</div>
                                    <div class="info-num">{{ videoTime }}</div>
                                </div>
                                <div class="video-info">
                                    <div class="info-label">视频大小</div>
                                    <div class="info-num">{{ videoSize }}</div>
                                </div>
                            </div>
                            <div class="reupload cursor" @click="showUpload = true">重新上传</div>
                        </div>
                        <div class="tips">
                            <div>支持的视频格式: mp4(推荐)、3gp、m3u8格式; 支持的编码格式:H.264、HEVC、MPEG-4 ;</div>
                            <div>视频大小不超过1GB; 当前店铺版本最大支持720高清转码;
                                具体规格根据原视频而定常见播放问题和转码问题
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="label">
                        <span class="white">*</span>
                        <span>视频封面</span>
                    </div>
                    <div class="video-box">
                        <div class="upload-box">
                            <a-spin size="small" type="loading" :spinning="spinning">
                                <div class="thumb">
                                    <img :src="params.thumb">
                                </div>
                            </a-spin>
                            
                            
                            <a-upload name="file" accept=".jpeg,.jpg,.png,.bmp,.webp" :showUploadList="false"
                                      :customRequest="uploadThumb">
                                <div class="reupload cursor">替换封面</div>
                            </a-upload>
                        
                        </div>
                        <div class="tips">
                            <div>图片格式必须为:png、bmp、jpeg、jpg; 不可大于2M; 建议使用png格式图片, 以保持最佳效果;
                                建议图片宽度为980px
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="btn-box">
            <div class="btn cursor" @click="sumbit">保存</div>
            <div class="cancel cursor" @click="back">取消</div>
        </div>
        
        <div class="upload-container" v-if="showUpload">
            <div class="content">
                <div class="header">
                    <div class="label">上传视频</div>
                    <div class="line"></div>
                    <div class="close cursor" @click="closeUpload">
                        <img src="/assets/images/video/upload-close.png">
                    </div>
                </div>
                <div class="upload">
                    <div class="add-video add-video-progress" v-if="isUpload">
                        <div class="text">{{ uploadTips }}</div>
                        <div class="progress">
                            <a-progress :percent="uploadPercent"/>
                        </div>
                        <div class="btn" @click="cancelUpload" v-if="uploadPercent < 100">取消上传</div>
                    </div>
                    
                    <a-upload name="file" accept=".mp4" :showUploadList="false"
                              :customRequest="customRequest" v-else>
                        <div class="add-video cursor">
                            <a-icon type="upload" style="font-size: 50px;color: #888;"/>
                            <div class="text">
                                <span>将视频拖拽到此处也可上传</span>
                                <div><small>仅支持上传H.264（AVC）编码的mp4文件</small></div>
                            </div>
                            <div class="btn">选择视频</div>
                        </div>
                    </a-upload>
                </div>
            </div>
        
        </div>
    </div>
</template>
<script>
import api from "@/repo/api";
import moment from "moment/moment";
import {UploadImage} from "@/utils/upload"
import video from "@/utils/video";

const qiniu = require('qiniu-js');

export default {
    name: "VideoUpload",
    data() {
        return {
            params: {
                id: 0,
                name: '',
                thumb: '',
            },
            detail: {},
            showUpload: false,
            isUpload: false,
            uploadFile: null,
            uploadPercent: 0,
            current: 0,
            subscription: null,
            spinning: false,
        }
    },
    mounted() {
        let detail = this.$route.query;
        console.log('detail', detail);
        this.params.id = detail.id;
        this.params.thumb = detail.thumb;
        this.params.name = detail.name.split('.')[0];
        
        this.detail = detail;
    },
    computed: {
        uploadTips() {
            let fileSize = this.uploadFile.size / 1024 / 1024;
            return `${this.uploadFile.name}, 视频大小${fileSize.toFixed(2)}M`
        },
        videoSize() {
            let size = Number(this.detail.size) / 1024 / 1024;
            return size.toFixed(2) + 'M'
        },
        videoTime() {
            let seconds = Number(this.detail?.info?.duration);
            var duration = moment.duration(Number(seconds), 'seconds');
            var h = duration.hours();
            var m = duration.minutes();
            var s = duration.seconds();
            return (h < 10 ? `0${h}` : h) + ':' + (m < 10 ? `0${m}` : m) + ':' + (s < 10 ? `0${s}` : s)
        },
    },
    methods: {
        async uploadThumb(e) {
            this.spinning = true;
            let Image = new UploadImage();
            const {url} = await Image.upload(e.file);
            this.params.thumb = url;
            this.spinning = false;
        },
        back() {
            this.$router.back();
        },
        handleChange(e) {
            console.log(e);
        },
        // 取消上传
        cancelUpload() {
            this.subscription.unsubscribe();
            this.isUpload = false;
            this.uploadFile = null;
            this.uploadPercent = 0;
        },
        closeUpload() {
            this.showUpload = false;
            this.isUpload = false;
            this.uploadFile = null;
            this.uploadPercent = 0;
        },
        customRequest(e) {
            const file = e.file;
            
            this.$loading.show();
            
            video.checkH264(file).then(() => {
                
                let size = Number(e.file.size) / 1024 / 1024;
                
                if (size > 5 && size < 1024) {
                    this.uploadFile = e.file;
                    this.isUpload = true;
                    this.getToken(e.file);
                } else {
                    if (size > 10240) {
                        this.$message.error('视频不能超过10GB');
                    }
                    if (size < 5) {
                        this.$message.error('视频不能小于5MB');
                    }
                }
            }).catch(e => {
                
                this.$message.error(e);
                
            }).finally(() => {
                
                this.$loading.hide();
                
            })
        },
        getToken(file) {
            let _this = this;
            api.post('/com/qi-niu-video-upload-token', {file_name: file.name, file_size: file.size}, res => {
                if (res.code == 0) {
                    let token = res.data.token;
                    let key = res.data.key;
                    
                    const config = {
                        chunkSize: 4,
                        useCdnDomain: true,
                        region: qiniu.region.z2
                    };
                    
                    const observable = qiniu.upload(file, key, token, config);
                    
                    var observer = {
                        next(res) {
                            let percent = Number(res.total.percent.toFixed(2));
                            _this.uploadPercent = percent;
                        },
                        error(err) {
                            console.log(err);
                        },
                        complete(res) {
                            console.log('complete', res);
                            if (res.code == 0) {
                                _this.detail = res.data;
                                _this.params.name = res.data.name.split('.')[0];
                                _this.closeUpload();
                                console.log(_this.detail);
                                _this.$update()
                            }
                        },
                    };
                    
                    this.subscription = observable.subscribe(observer) // 上传开始
                    console.log('subscription', this.subscription);
                }
            })
        },
        sumbit() {
            console.log(this.params);
            api.post('/ast-space/update-file-info', this.params, res => {
                if (res.code == 0) {
                    this.$message.success('保存成功！');
                    this.$router.back();
                }
            })
        },
    }
}
</script>

<style lang="less" scoped>
.upload-container {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    
    .content {
        width: 408px;
        background: #FFFFFF;
        border-radius: 2px;
        padding: 24px;
        position: relative;
        
        .header {
            .close {
                width: 56px;
                height: 56px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 0;
                right: 0;
                
                img {
                    width: 16px;
                    height: 16px;
                }
            }
            
            .label {
                color: #333333;
                font-size: 16px;
                font-weight: bold;
                line-height: 22px;
            }
            
            .line {
                width: 360px;
                height: 1px;
                background: #EEEEEE;
                margin: 16px 0;
            }
        }
        
        .upload {
            width: 360px;
            height: 300px;
            
            .add-video {
                width: 360px;
                height: 300px;
                border-radius: 8px;
                border: 1px dashed #999999;
                display: flex;
                flex-direction: column;
                justify-content: center;
                
                .progress {
                    width: 80%;
                    margin: 32px auto 0;
                }
                
                .text {
                    color: #333333;
                    font-size: 14px;
                    margin-top: 16px;
                    text-align: center;
                }
                
                .btn {
                    width: fit-content;
                    margin: 64px auto 0;
                }
                
                &:hover {
                    border-color: #1890FF;
                }
            }
            
            .add-video-progress {
                &:hover {
                    border: 1px dashed #999999;
                }
            }
        }
        
    }
}

.btn-box {
    display: flex;
    align-items: center;
    margin-top: 32px;
    
    div {
        width: 80px;
        height: 34px;
        line-height: 34px;
        text-align: center;
        padding: 0;
    }
    
    .cancel {
        border: 1px solid #333333;
        color: #333333;
        font-size: 14px;
        margin-left: 16px;
    }
}

.container {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    margin-top: 32px;
    
    .head {
        width: 100%;
        height: 56px;
        line-height: 56px;
        border-bottom: 1px solid #EEEEEE;
        padding: 0 24px;
        position: relative;
        
        .label {
            color: #000;
            font-size: 16px;
        }
        
        .line {
            width: 2px;
            height: 14px;
            background: #333333;
            position: absolute;
            left: 0;
            top: 21px;
        }
    }
    
    .content-box {
        width: 100%;
        padding: 24px;
        
        .row {
            display: flex;
            margin-bottom: 24px;
            
            .label {
                color: #333333;
                font-size: 14px;
                margin-top: 10px;
                margin-right: 16px;
                
                .red {
                    color: #FF2323;
                }
                
                .white {
                    color: #FFFFFF;
                }
            }
            
            .input-box {
                width: 577px;
                background: #FFFFFF;
                border: 1px solid #EEEEEE;
                padding: 10px 16px;
                display: flex;
                align-items: center;
                position: relative;
                
                .num {
                    color: rgba(0, 0, 0, 0.25);
                    font-size: 14px;
                    line-height: 20px;
                    position: absolute;
                    right: 16px;
                    bottom: 10px;
                }
                
                input,
                textarea {
                    width: 480px;
                    height: 20px;
                    line-height: 20px;
                    resize: none;
                    border: none;
                    outline: none;
                    padding: 0;
                    color: #333;
                    font-size: 14px;
                    box-shadow: none !important;
                }
                
                textarea {
                    max-height: 40px;
                    line-height: 20px;
                }
            }
            
            .video-box {
                
                .upload-box {
                    display: flex;
                    align-items: flex-end;
                    
                    .thumb {
                        width: 64px;
                        height: 64px;
                        
                        img {
                            width: 64px;
                            height: 64px;
                            object-fit: contain;
                            border: 1px solid #EEEEEE;
                        }
                    }
                    
                    .video {
                        width: 456px;
                        height: 128px;
                        background: #FFFFFF;
                        border: 1px solid #EEEEEE;
                        display: flex;
                        padding: 16px;
                        
                        .video-thumb {
                            width: 96px;
                            height: 96px;
                            margin-right: 16px;
                            
                            img {
                                width: 96px;
                                height: 96px;
                                object-fit: contain;
                                border: 1px solid #EEEEEE;
                            }
                        }
                        
                        .video-info {
                            margin-right: 32px;
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-end;
                            
                            .info-label {
                                color: #999999;
                                font-size: 14px;
                                line-height: 20px;
                            }
                            
                            .info-num {
                                color: #333333;
                                font-size: 16px;
                                font-weight: bold;
                                line-height: 22px;
                                margin-top: 8px;
                            }
                        }
                    }
                    
                    .add {
                        width: 64px;
                        height: 64px;
                        border: 1px solid #EEEEEE;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        
                        img {
                            width: 16px;
                            height: 16px;
                        }
                    }
                    
                    .reupload {
                        color: #FBB03B;
                        font-size: 14px;
                        margin-left: 16px;
                    }
                }
                
                .tips {
                    color: #999999;
                    font-size: 12px;
                    margin-top: 12px;
                    line-height: 17px;
                }
            }
        }
        
    }
}
</style>